/* **********************************
 * ewmModules.js
 ********************************** */
// ページ幅でSP判定
const isSP = () => {
  return window.matchMedia('(max-width: 768px)').matches;
};

// マウスオーバー（'.js-mouseover' 画像ファイル末尾 _on, _off を置換、巻き込み注意）
const init_mouseover = (mouseoverClassName) => {
  $(mouseoverClassName).each(function () {
    var src_off = $(this).find('img').attr('src');
    var src_on = src_off.replace('_off', '_on');
    $('<img />').attr('src', src_on);
    $(this).hover(
      function () {
        if (isSP()) {
          $(this).find('img').attr('src', src_off); // SP時はホバー演出しない
        } else {
          $(this).find('img').attr('src', src_on); // PC時のみホバー演出する
        }
      },
      function () {
        $(this).find('img').attr('src', src_off);
      }
    );
  });
};

// スムーススクロール "a.js-pageScroll"
const init_pageScroll = () => {
  $('[href^="#"]').on('click', function () {
    var hrefStr = $(this).attr('href');
    if (!hrefStr || hrefStr === '#') {
      return;
    }

    // SP時はFIXヘッダ高さの値をマイナス
    var heightSpHeader = 0;
    heightSpHeader = $('.l-header').outerHeight() * -1;

    var offsetObj = $(hrefStr).offset();
    if (offsetObj && offsetObj.top) {
      $('html,body').animate({ scrollTop: offsetObj.top + heightSpHeader }, 'slow', 'swing');
    } else {
      $('html,body').animate({ scrollTop: heightSpHeader }, 'slow', 'swing');
    }
    return false;
  });
};

// ページトップへ戻るボタンのアニメーション
const init_returnTop = (scrollFadeClass) => {
  var $pageTop = $(scrollFadeClass);
  $pageTop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) {
      $pageTop.fadeIn();
    } else {
      $pageTop.fadeOut();
    }
  });
};

// tabel要素のレスポンシブ対応
const init_responsiveTable = (elm) => {
  $(elm).each(function () {
    var $this = $(this);

    // is-noStyleクラス: レスポンシブ処理を行わない
    if ($this.hasClass('is-noStyle')) {
      return true; // 後続のtableも処理したいので continue
    }

    var $cells = $this.find('tr:first > *');
    var table_size = $this.find('tr:first > *').length;

    for (var i = 0; i < $cells.length; i++) {
      var $cell = $cells.eq(i);
      var colspan = $cell.attr('colspan');

      if (colspan) {
        table_size = table_size + (colspan - 1);
      }
    }

    if (table_size > 1 || $this.is('.sp-scrollTable')) {
      $this.wrap('<div class="c-tableWrapper"></div>');

      var $wrapper = $this.closest('.c-tableWrapper');
      $wrapper.before('<p class="c-tableCaption"><span>※この表は横にスクロールできます</span></p>');
    }
  });
};

// IE判定（HTML要素に isIEクラスを付与）
const addClass_isIE = () => {
  var userAgent = window.navigator.userAgent.toLowerCase();
  var ieFlag = userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1;
  if (ieFlag) {
    $('html').addClass('isIE');
  }
  return ieFlag;
};

// Android判定（HTML要素に isAndroidクラスを付与）
const addClass_isAndroid = () => {
  var userAgent = window.navigator.userAgent.toLowerCase();
  var androidFlag = userAgent.indexOf('android') > 0;
  if (androidFlag) {
    $('html').addClass('isAndroid');
  }
  return androidFlag;
};

// アコーディオン
const init_accordion = () => {
  // 初期化処理
  $('.js-accordion').off('click', '.js-accordion__head');
  $('.js-accordion__body').hide();
  // eslint-disable-next-line no-unused-vars
  $('.js-accordion').on('click', '.js-accordion__head', function (e) {
    var $accordion = $(this).closest('.js-accordion');
    if (!$accordion.hasClass('is-open')) {
      $accordion.addClass('is-open').children('.js-accordion__body').stop().slideDown();
    } else {
      $accordion
        .children('.js-accordion__body')
        .stop()
        .slideUp(function () {
          $accordion.removeClass('is-open');
        });
    }
    return false;
  });

  // //アコーディオンの展開
  // var getURL = location.href;
  // if ( getURL.indexOf('?q=') === -1) {
  //   var URLID = getURL.split('#');
  //   if(URLID[1]) {
  //     var $getID = '#' + URLID[1];
  //     if($($getID).is('.accordion')) {
  //       $($getID).addClass('is-open');
  //       $($getID).children('.js-accordion__body').show();
  //     }
  //   }
  // }
};

/* **********************************
 * _burgerMenu.js
 ********************************** */
const init_burgerMenu = () => {
  var $trigger = $('.c-burgerMenu__trigger');
  var openClass = 'is-burgerMenu-open';

  // メニューボタン押下時
  $trigger.on('click', function () {
    if ($('body').hasClass(openClass)) {
      closeMenu();
    } else {
      openMenu();
    }
  });

  // リサイズ時
  $(window).on('resize', function () {
    if (!isSP()) {
      closeMenu(); // PC幅時は強制的に閉じる
    }
  });

  // メニュー項目押下時: メニューパネルを閉じる
  $('.c-burgerMenu__list__link').on('click', function () {
    closeMenu();
  });

  function openMenu() {
    $('body').addClass(openClass);
  }

  function closeMenu() {
    $('body').removeClass(openClass);
  }
};

/* **********************************
 * _toppageMvSlider.js
 ********************************** */
const init_toppageMvSlider = (target) => {

  const playBtnSel = '.p-topMvSlider__toggle';
  const statsCls = "is-autoplay";

  // toppageMvSlider: スライダー生成 ............................
  $(target).slick({
    centerMode: true,
    slidesToShow: 1,
    variableWidth: true,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 5000,
    dots: true,
    appendDots: $('.p-topMvSlider__pagination'),
    arrows: false,
    fade: false,
  });

  // toppageMvSlider: スライダー再生制御：play/pause ............................
  $(playBtnSel).on('click', function() {
    if( $(playBtnSel).hasClass(statsCls) ){
      $(playBtnSel).removeClass(statsCls);
      $(target).slick('pause');
    }else{
      $(playBtnSel).addClass(statsCls);
      $(target).slick('play');
    }
    return false;
  });

  // 初期動作 ............................
  $(playBtnSel).addClass(statsCls);
  $(target).slick('play');

};

/* **********************************
 * scripts.js (Entry Point)
 ********************************** */

$(function () {
  init_pageScroll();
  init_returnTop(".js-scrollFade");
  init_burgerMenu();
  init_toppageMvSlider(".js-topMvSlider");
});
